import { RedocStandalone } from 'redoc';

function App() {
  return (
    <RedocStandalone
       specUrl={'https://nft.vlinder.io/explorer/openapi.json'}
      options={{
        nativeScrollbars: true,
        theme: { colors: { primary: { main: '#1a4677' } } },
        hideDownloadButton: true,
      }}
    />
  );
}

export default App;
